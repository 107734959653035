import { makeStyles, Modal, Table, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAllCollectionSiteState,
  getAllHarvestPoints,
} from '../../api/apiBack';
import addCollectionSiteState from '../../store/collectionSiteState/actionCreator';
import GenericTableHead from '../GenericTable/GenericTableHead';
import HarvestPointRow from './HarvestPointRow';
import HarvestPointRowCreate from './HarvestPointRowCreate';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

// table columns title
const columns = ['latitude', 'longitude', 'Type cendrier', 'Action'];

/**
 *
 * @param {*} param0
 */
const HarvestPointModal = ({ harvestSiteId, mustOpen, mustClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [harvestPoints, setHarvestPoints] = useState([]);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // add created point to table
  const addRow = (data) => {
    setHarvestPoints(harvestPoints.concat(data));
  };

  const promiseSuccessCollectionSiteState = (results) => {
    dispatch(addCollectionSiteState(results.data));
  };

  const promiseSuccessHarvestPoints = (results) => {
    setHarvestPoints(results.data);
  };

  const promiseFail = () => {};

  useEffect(() => {
    const harvestPointsPromise = getAllHarvestPoints();
    harvestPointsPromise.then(promiseSuccessHarvestPoints, promiseFail);

    const collectionSiteStatePromise = getAllCollectionSiteState();
    collectionSiteStatePromise.then(
      promiseSuccessCollectionSiteState,
      promiseFail
    );
  }, []);

  return (
    <Modal open={mustOpen} onClose={mustClose}>
      <div style={modalStyle} className={classes.paper}>
        <h2>Points de collecte</h2>
        <form onSubmit={handleSubmit}>
          <Table>
            <GenericTableHead headArray={columns} className="" />
            <TableBody>
              {harvestPoints.map((h) => {
                return (
                  <HarvestPointRow
                    key={h.id}
                    id={h.id}
                    latitude={h.geolocLat}
                    longitude={h.geolocLong}
                  />
                );
              })}
              <HarvestPointRowCreate
                addRow={addRow}
                harvestSiteId={harvestSiteId}
              />
            </TableBody>
          </Table>
        </form>
      </div>
    </Modal>
  );
};

HarvestPointModal.propTypes = {
  mustOpen: PropTypes.bool.isRequired,
  harvestSiteId: PropTypes.number.isRequired,
  mustClose: PropTypes.func.isRequired,
};

export default HarvestPointModal;
