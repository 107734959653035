import Grid from '@material-ui/core/Grid';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

import '../../styles/Footer.css';
import useStyles from '../../styles/CSS';

function Footer() {
  const classes = useStyles();
  return (
    <Grid className={classes.rootFooter} style={{ backgroundColor: '#016634' }}>
      <Grid container justify="space-evenly" className="footerMain">
        <Grid style={{ margin: 'auto' }}>
          <Grid
            container
            item
            direction="row"
            className={classes.iconContainer}
          >
            <a item href=" https://www.instagram.com/tree6clope" target="blank">
              <FaInstagram size="5em" style={{ paddingTop: '1em' }} />
            </a>
            <a item href="https://www.facebook.com/re6clope" target="blank">
              <FaFacebook size="4.7em" style={{ paddingTop: '1.2em' }} />
            </a>
            <p style={{ margin: 'auto' }}>Tree6clope</p>
          </Grid>
        </Grid>
        <Grid item xs={4} lg={3} style={{ margin: '20px' }}>
          <Grid container spacing={3} className={classes.textFooterContainer}>
            <Grid item xs={6} className={classes.textFooterMention}>
              {/* link for "mentions légales" coming soon instead replace by <p> */}
              {/* <a
                href="#"
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  marginBottom: '1em',
                }}
              >
                Mentions légales
              </a> */}
              <p className={classes.textFooter}>Mention légales</p>

              {/* link for "Condition d'utilisation" coming soon instead replace by <p> */}
              {/* <a href="#" style={{ textDecoration: 'none', display: 'block' }}>
                Conditions d&#39;utilisation
              </a> */}
              <p className={classes.textFooter}>
                {' '}
                Conditions d&#39;utilisation
              </p>

              <p className={classes.textFooter}>Tree6clope 2021</p>
            </Grid>
            <Grid item xs={6} className={classes.textFooterAdress}>
              <p className={classes.textFooter}>TREE6CLOPE</p>
              <p className={classes.textFooter}>7, place Beaurivage</p>
              <p className={classes.textFooter}>64200 Biarritz</p>
              <a
                href="mailto: contact@tree6clope.com"
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  fontSize: '10px',
                }}
                className={classes.textFooter}
              >
                contact@tree6clope.com
              </a>
              <a
                href="tel:0673733156"
                style={{
                  textDecoration: 'none',
                  fontSize: '10px',
                }}
              >
                0673733156
              </a>
            </Grid>
            <Grid style={{ width: '100%', textAlign: 'center' }}>
              <p
                style={{
                  paddingBottom: '10px',
                }}
              >
                Site internet réalisé par les élèves de la{' '}
                <a
                  href="https://www.instagram.com/wildcodeschool_biarritz/"
                  target="blank"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  Wild Code School Biarritz
                </a>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
