import { Button, FormControl, TableCell, TextField } from '@material-ui/core';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function CreateLabelCard({ handleAdd }) {
  const [currentDatas, setCurrentDatas] = useState([]);

  const handleField = (e) => {
    setCurrentDatas((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCreate = (e) => {
    e.preventDefault();
    handleAdd(currentDatas);
  };

  return (
    <>
      <TableCell>
        <FormControl>
          <TextField
            variant="filled"
            className="test"
            type="text"
            id="label"
            value={currentDatas.label}
            label="Créer"
            name="label"
            onChange={handleField}
            style={{ width: '300px' }}
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          type="submit"
          onClick={handleCreate}
          style={{ width: '100%' }}
        >
          creer
        </Button>
      </TableCell>
    </>
  );
}

CreateLabelCard.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};
