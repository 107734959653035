import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { connect, useDispatch, useSelector } from 'react-redux';

import { getAllAdherents } from '../api/apiBack';
import { setAdherents } from '../store/adherent/actionCreator';
import AdherentFilter from '../components/Adherent/AdherentFilter';

const mapStateToProps = (state) => {
  return {
    geolocLat: state.adherent.geolocLat,
    geolocLong: state.adherent.geolocLong,
  };
};

function Map() {
  const mapCenterPosition = [43.463458, -1.511534];

  const dispatch = useDispatch();
  const adherentList = useSelector((state) => state.adherent.adherents);
  const token = localStorage.getItem('TOKEN');

  const status = useSelector((state) => state.adherent.status);
  const typology = useSelector((state) => state.adherent.typology);
  const distinctZipCode = useSelector(
    (state) => state.adherent.distinctZipCode
  );
  const employeeQuantity = useSelector(
    (state) => state.adherent.employeeQuantity
  );
  const distanceType = useSelector((state) => state.adherent.distanceType);

  const succesPromise = (result) => {
    dispatch(setAdherents(result.data));
  };
  const failPromise = (err) => {
    return err;
  };

  useEffect(() => {
    const getDatas = async () => {
      const promiseAdherent = getAllAdherents();
      promiseAdherent.then(succesPromise, failPromise);
    };

    getDatas();
  }, [token]);

  return (
    <>
      <AdherentFilter />
      <MapContainer
        style={{ height: '500px', width: '100%' }}
        center={mapCenterPosition}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution="Tree6Clope map using Leaflet"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {adherentList
          .filter((f) => f.statusId === status || status === 0)
          .filter(
            (f) => f.zipCodeId === distinctZipCode || distinctZipCode === 0
          )
          .filter((t) => t.typologyId === typology || typology === 0)
          .filter(
            (t) => t.distanceTypeId === distanceType || distanceType === 0
          )
          .filter(
            (q) =>
              q.employeeQuantityId === employeeQuantity ||
              employeeQuantity === 0
          )
          .map((item) => {
            return (
              <Marker
                key={item.id}
                position={[item.geolocLat, item.geolocLong]}
              >
                <Popup>{item.sign}</Popup>
              </Marker>
            );
          })}
      </MapContainer>
    </>
  );
}

export default connect(mapStateToProps)(Map);
