import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

const FormDate = ({
  selectedDate,
  onChange = null,
  label,
  name,
  disabled = false,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        variant="filled"
        id={name}
        name={name}
        label={label}
        value={selectedDate}
        format="d MMM yyyy"
        onChange={onChange}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

FormDate.defaultProps = {
  disabled: false,
  onChange: null,
};

FormDate.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default FormDate;
