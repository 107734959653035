import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getWeightByCriterias } from '../../api/apiBack';
import PieGraphic2Component from './PieGraphic2Component';

const token = localStorage.getItem('TOKEN');

export default function PieGraphic2({ dateStart, dateEnd }) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (token) {
      getWeightByCriterias('zipCode', dateStart, dateEnd).then((results) => {
        return setDatas(results.data);
      });
    }
  }, [token, dateStart, dateEnd]);

  return <PieGraphic2Component datas={datas} />;
}

PieGraphic2.propTypes = {
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.func.isRequired,
};
