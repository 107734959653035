import { LOGIN, LOGOUT } from './actionTypes';

const initState = {
  token: null,
};

function adminReducer(state = initState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
}

export default adminReducer;
