import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAllStatus } from '../../api/apiBack';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const FormSelectStatus = ({ selected, onChange }) => {
  const classes = useStyles();
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(selected);

  const statusSucces = (result) => {
    setStatus(result.data);
  };

  useEffect(() => {
    getAllStatus().then((result) => {
      statusSucces(result);
    });
  }, []);

  const handleChange = (e) => {
    setSelectedStatus(e.target.value);
    onChange(e);
  };

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="statusId">Statut</InputLabel>
      <Select
        style={{ width: '25%', marginBottom: '5px' }}
        variant="filled"
        name="statusId"
        value={selectedStatus}
        onChange={handleChange}
      >
        <option value="0">Tous</option>
        {status.map((s) => (
          <option key={s.id} value={s.id}>
            {s.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

FormSelectStatus.propTypes = {
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormSelectStatus;
