// import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { putAdherent } from '../api/apiBack';
import AdherentFormComponent from '../components/Adherent/AdherentFormComponent';

function AdherentFormEdit() {
  const message = 'Adhérent modifié';
  const { id } = useParams();

  const request = (adherent) => {
    const putData = async () => {
      putAdherent(id, adherent)
        .then(() => {
          toast.success(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.error(err.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    };

    putData();
  };

  return <AdherentFormComponent id={id} axiosRequest={request} />;
}

export default AdherentFormEdit;
