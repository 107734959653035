import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getWeightByCriterias } from '../../api/apiBack';
import PieGraphicComponent from './PieGraphicComponent';

const token = localStorage.getItem('TOKEN');

export default function PieGraphic({ dateStart, dateEnd }) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (token) {
      getWeightByCriterias('typology', dateStart, dateEnd).then((results) => {
        return setDatas(results.data);
      });
    }
  }, [token, dateStart, dateEnd]);

  return <PieGraphicComponent datas={datas} />;
}

PieGraphic.propTypes = {
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.func.isRequired,
};
