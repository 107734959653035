import ADD_COLLECTION_SITE_STATE_LIST from './actionTypes';

export default function addCollectionSiteState(data) {
  return {
    type: ADD_COLLECTION_SITE_STATE_LIST,
    payload: {
      collectionSiteStates: data,
    },
  };
}
