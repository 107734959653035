import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import FormZipCode from '../../Adherent/FormZipCode';
import { postAdherent } from '../../../api/apiBack';
import FormSelectStatus from '../../Adherent/FormSelectStatus';
import FormSelectTypology from '../../Adherent/FormSelectTypology';
import FormSelectDistanceType from '../../Adherent/FormSelectDistanceType';
import FormSelectEmployeeQuantity from '../../Adherent/FormSelectEmployeeQuantity';

import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const adherentInit = {
  sign: '',
  legalEntity: '',
  streetNumber: '',
  streetName: '',
  primaryPhone: '',
  email: '',
  subscribeNewsletter: false,
  active: false,
  seasonal: false,
  publicAccess: false,
  terrace: false,
  terraceTableQuantity: 0,
  renewalSubscription: new Date().toISOString(),
  statusId: 1,
  typologyId: 1,
  zipCodeId: 1,
  distanceTypeId: 1,
  employeeQuantityId: 1,
  godfatherId: 0,
};

export default function AdherentTab() {
  const message = 'Nouvel Adhérent créé';
  const classes = useStyles();
  const [adherent, setAdherent] = useState(adherentInit);
  // const adherentStore = useSelector(
  //   (state) =>
  //     state.adherent.adherents.filter((a) => a.id === parseInt(id, 10))[0]
  // );

  useEffect(() => {
    setAdherent(adherentInit);
  }, []);

  const fail = () => {};

  const handleField = (e) => {
    setAdherent((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheck = (e) => {
    setAdherent((state) => ({
      ...state,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleDatePicker = (e) => {
    setAdherent((state) => ({
      ...state,
      renewalSubscription: e.toISOString(),
    }));
  };

  const handleCreate = (e) => {
    e.preventDefault();
    postAdherent(adherent, fail)
      .then(() => {
        toast.success(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <form onSubmit={handleCreate}>
      <FormControl>
        <Grid container spacing="0" style={{ height: '60vh' }}>
          <Grid
            item
            sm="6"
            spacing="0"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            {/* input Sign */}
            <TextField
              type="text"
              label="Enseigne"
              value={adherent.sign}
              id="sign"
              name="sign"
              onChange={handleField}
            />

            {/* input legalEntity */}
            <TextField
              type="text"
              label="Statut legal"
              value={adherent.legalEntity}
              id="legalEntity"
              name="legalEntity"
              onChange={handleField}
            />

            {/* input streetNumber */}
            <TextField
              type="text"
              label="N° de rue"
              value={adherent.streetNumber}
              id="streetNumber"
              name="streetNumber"
              onChange={handleField}
            />

            {/* input streetName */}
            <TextField
              type="text"
              label="Nom de rue"
              value={adherent.streetName}
              id="streetName"
              name="streetName"
              onChange={handleField}
            />

            {/* input postalBox */}
            <TextField
              type="text"
              label="Boite postale"
              value={adherent.postalBox || ''}
              id="postalBox"
              name="postalBox"
              onChange={handleField}
            />

            <FormZipCode onChange={handleField} />

            <TextField
              type="text"
              id="primaryPhone"
              value={adherent.primaryPhone}
              name="primaryPhone"
              label="primaryPhone"
              onChange={handleField}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                id="renewalSubscription"
                name="renewalSubscription"
                label="Date de renouvellement"
                value={adherent.renewalSubscription}
                format="d MMM yyyy"
                onChange={handleDatePicker}
              />
            </MuiPickersUtilsProvider>

            <TextField
              className="test"
              type="text"
              id="email"
              value={adherent.email}
              label="Email"
              name="email"
              onChange={handleField}
            />
          </Grid>
          <Grid
            item
            xs="6"
            spacing="0"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="subscribeNewsletter"
                  checked={adherent.subscribeNewsletter}
                  onChange={handleCheck}
                />
              }
              label="Newsletter"
              className={classes.root}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="active"
                  checked={adherent.active}
                  onChange={handleCheck}
                />
              }
              label="Actif"
              className={classes.root}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="seasonal"
                  checked={adherent.seasonal}
                  onChange={handleCheck}
                />
              }
              label="seasonal"
              className={classes.root}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="publicAccess"
                  checked={adherent.publicAccess}
                  onChange={handleCheck}
                />
              }
              label="publicAccess"
              className={classes.root}
            />
            <Grid container spacing="0" style={{ justifySelf: 'start' }}>
              <Grid item xs="6">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="terrace"
                      checked={adherent.terrace}
                      onChange={handleCheck}
                    />
                  }
                  className={classes.root}
                  label="Terrasse"
                />
              </Grid>
              <Grid item xs="6">
                {adherent.terrace ? (
                  <TextField
                    variant="filled"
                    type="text"
                    id="terraceTableQuantity"
                    value={adherent.terraceTableQuantity}
                    label="Tables"
                    name="terraceTableQuantity"
                    onChange={handleField}
                  />
                ) : (
                  <div />
                )}
              </Grid>
            </Grid>

            <FormSelectStatus
              selected={adherent.statusId}
              onChange={handleField}
            />

            <FormSelectTypology
              selected={adherent.typologyId}
              onChange={handleField}
            />

            <FormSelectDistanceType
              selected={adherent.distanceTypeId}
              onChange={handleField}
            />

            <FormSelectEmployeeQuantity
              selected={adherent.employeeQuantityId}
              onChange={handleField}
            />
          </Grid>
        </Grid>
      </FormControl>
      <Button type="submit" onClick={handleCreate}>
        creer
      </Button>
      <ToastContainer />
    </form>
  );
}
