import { toast, ToastContainer } from 'react-toastify';
import { Grid, TextField, FormControl, Button } from '@material-ui/core';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { postAdmin } from '../../../api/apiBack';

import 'react-toastify/dist/ReactToastify.css';

export default function CreateAdminCard({ handleAdd }) {
  const message = 'Nouvelle administrateur créé';
  const [admin, setAdmin] = useState({
    firstname: null,
    lastname: null,
    password: 'tree6clope',
    email: null,
    phone: null,
  });
  const [update, setUpdate] = useState(true);

  const handleCreate = (e) => {
    e.preventDefault();
    postAdmin(admin)
      .then((results) => {
        setUpdate(!update);
        handleAdd(results.data);

        toast.success(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleField = (e) => {
    setAdmin((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <form onSubmit={handleCreate} style={{ width: '100%' }}>
        <FormControl style={{ margin: '80px' }}>
          <Grid container spacing="0">
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="firstname"
              value={admin.firstname}
              label="Firstname"
              name="firstname"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="lastname"
              value={admin.lastname}
              label="Lastname"
              name="lastname"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="email"
              value={admin.email}
              label="Email"
              name="email"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="phone"
              value={admin.phone01}
              label="Phone number #1"
              name="phone"
              onChange={handleField}
            />
          </Grid>
        </FormControl>
      </form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            padding: '5px',
            backgroundColor: '#70696d',
            color: 'white',
          }}
          type="submit"
          onClick={handleCreate}
        >
          créer
        </Button>
        <ToastContainer />
      </div>
    </div>
  );
}

CreateAdminCard.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};
