import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import ExploreIcon from '@material-ui/icons/Explore';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import RoomIcon from '@material-ui/icons/Room';
import BuildIcon from '@material-ui/icons/Build';

const Items = [
  { route: '/home/accueil', name: 'Accueil', icon: <DashboardIcon /> },
  { route: '/home/adherents', name: 'Adhérents', icon: <PeopleIcon /> },
  { route: '/home/donnees', name: 'Données', icon: <BarChartIcon /> },
  { route: '/home/map', name: 'Map', icon: <ExploreIcon /> },
  { route: '/home/manage', name: 'Manage', icon: <BuildIcon /> },
];

const Items2 = [
  { route: '/home/collecte', name: 'Collecte', icon: <DashboardIcon /> },
];

export const mainListItems = (
  <div>
    {Items.map((item) => {
      return (
        <Link
          Link
          to={item.route}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        </Link>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/adherents">
        //       <PeopleIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/adherents">
        //     <ListItemText primary="Adhérents" />
        //   </Link>
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/donnees">
        //       <PeopleIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/donnees">
        //     <ListItemText primary="Données" />
        //   </Link>
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/map">
        //       <ExploreIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/map">
        //     <ListItemText primary="Map" />
        //   </Link>
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <ShoppingCartIcon />
        //   </ListItemIcon>
        //   <ListItemText primary="Articles" />
        // </ListItem>
        // <ListItem button>
        //   <ListItemIcon>
        //     <Link to="/home/statistiques">
        //       <BarChartIcon />
        //     </Link>
        //   </ListItemIcon>
        //   <Link to="/home/statistiques">
        //     <ListItemText primary="Statistiques" />
        //   </Link>
        // </ListItem>
      );
    })}
  </div>
);

export const secondaryListItems = (
  <div>
    {Items2.map((item) => {
      return (
        <Link
          to={item.route}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        </Link>
      );
    })}
  </div>
);
