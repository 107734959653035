import { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { AdherentsDistinctZipCode, getAllTypology } from '../../api/apiBack';

import {
  setTypology,
  setDistinctZipCode,
  setDistinctZipCodeList,
  setTypologyList,
} from '../../store/adherent/actionCreator';

import '../Adherent/adherentFilter.css';

const useStyles = makeStyles({
  select: {
    width: '20%',
  },
});

function CollecteSelectFilter() {
  const classes = useStyles();
  const typologyList = useSelector((state) => state.adherent.typologyList);
  const distinctZipCodeList = useSelector(
    (state) => state.adherent.distinctZipCodeList
  );

  const dispatch = useDispatch();

  // reset selected
  const reset = () => {
    dispatch(setDistinctZipCode(0));
    dispatch(setTypology(0));
  };

  useEffect(() => {
    getAllTypology().then((result) => {
      dispatch(setTypologyList(result.data));
    });
    AdherentsDistinctZipCode().then((result) => {
      dispatch(setDistinctZipCodeList(result.data));
    });

    return () => reset();
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item sm={6}>
          <FormControl className={classes.select}>
            <InputLabel id="demo-simple-select-label">Typologie</InputLabel>
            <Select
              style={{
                marginRight: '5px',
                marginBottom: '5px',
                width: '250px',
              }}
              variant="filled"
              name="typology"
              id="typology"
              onChange={(e) => {
                dispatch(setTypology(parseInt(e.target.value, 10)));
              }}
            >
              <option value="0">Tous</option>
              {typologyList.map((t) => (
                <option key={t.label} value={t.id}>
                  {t.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl className={classes.select}>
            <InputLabel id="demo-simple-select-label">Ville</InputLabel>
            <Select
              style={{
                marginRight: '5px',
                marginBottom: '5px',
                width: '250px',
              }}
              variant="filled"
              name="distinctZipCode"
              id="distinctZipCode"
              onChange={(e) => {
                dispatch(setDistinctZipCode(parseInt(e.target.value, 10)));
              }}
            >
              <option value="0">Tous</option>
              {distinctZipCodeList.map((t) => (
                <option key={t.zipCode.city} value={t.zipCode.inseeId}>
                  {t.zipCode.city}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default CollecteSelectFilter;
