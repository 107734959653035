import {
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCitiesByZipCode, getZipCodeById } from '../../api/apiBack';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const FormZipCode = ({ selectedId, onChange }) => {
  const classes = useStyles();
  const [zipCodes, setZipCodes] = useState([]);
  const [selectedZipCode, setSelectedZipCode] = useState({});
  const [selectedDisplayCity, setSelectedDisplayCity] = useState(0);

  // get cities by postal code
  const getCities = async (cp) => {
    getCitiesByZipCode(cp).then((results) => {
      setZipCodes(results.data);
      setSelectedZipCode(results.data[0]);
    });
  };

  useEffect(() => {
    if (selectedId) {
      getZipCodeById(selectedId).then((results) => {
        setSelectedZipCode(results.data);
        getCities(results.data.zipCode);
      });
    }
  }, [selectedId]);

  useEffect(() => {
    setSelectedDisplayCity(selectedZipCode.inseeId);
  }, [zipCodes, selectedZipCode]);

  const handleZipCode = (e) => {
    if (e.target.value.length <= 5) {
      setSelectedZipCode(e.target.value);
    }

    if (e.target.value.length === 5) {
      getCities(e.target.value);
    }
  };

  const handleChange = (e) => {
    setSelectedDisplayCity(e.target.value);
    onChange(e);
  };

  return (
    <>
      {/* input zipCode */}
      <TextField
        style={{ width: '20%' }}
        variant="filled"
        type="text"
        label="Code Postal"
        value={selectedZipCode ? selectedZipCode.zipCode : ''}
        id="zipCode"
        name="zipCode"
        onChange={handleZipCode}
      />
      {/* select City */}
      <FormControl className={classes.root}>
        <InputLabel htmlFor="zipCodeId">City</InputLabel>
        <Select
          style={{ marginBottom: '5px' }}
          variant="filled"
          name="zipCodeId"
          value={selectedDisplayCity}
          onChange={handleChange}
        >
          {zipCodes.map((s) => (
            <option key={s.id} value={s.inseeId}>
              {s.city}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

FormZipCode.defaultProps = {
  selectedId: null,
};
FormZipCode.propTypes = {
  selectedId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default FormZipCode;
