import ADD_COLLECTION_SITE_STATE_LIST from './actionTypes';

const initialState = {
  collectionSitesStates: [],
};

const collectionSiteStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COLLECTION_SITE_STATE_LIST:
      return {
        ...state,
        collectionSitesStates: action.payload.collectionSitesStates,
      };
    default:
      return state;
  }
};

export default collectionSiteStateReducer;
