import { FormControl, InputLabel, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { getAllCivility } from '../../api/apiBack';

export default function FormCivility({ selectedCivId, onChange }) {
  const [civility, setCivility] = useState([]);
  const [selectedCivility, setSelectedCivility] = useState({});

  // send id to parent and set in this component state
  // for display the good selected civility
  const handleChange = (e) => {
    setSelectedCivility(e.target.value);
    onChange(e);
  };

  // get all civilities for the select
  useEffect(() => {
    getAllCivility().then((results) => setCivility(results.data));
  }, []);

  // update the selectedId for render
  useEffect(() => {
    setSelectedCivility(selectedCivId);
  }, [selectedCivId]);

  return (
    <>
      <FormControl>
        <InputLabel htmlFor="civilityId">Civilité</InputLabel>
        <Select
          style={{ marginTop: '5px', marginBottom: '5px', width: '100%' }}
          variant="filled"
          name="civilityId"
          value={selectedCivility}
          onChange={handleChange}
        >
          {civility.map((s) => (
            <option key={s.id} value={s.id}>
              {s.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

FormCivility.propTypes = {
  selectedCivId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
