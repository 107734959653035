/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ManageTab from '../components/Manage/ManageTab';
import TypologyTab from '../components/Manage/TypologyTab';
import CivilityTab from '../components/Manage/CivilityTab';
import StatusTab from '../components/Manage/StatusTab';

import useStyles from '../styles/CSS';
import DistanceTypeTab from '../components/Manage/DistanceTypeTab';
import AdherentTab from '../components/Manage/Adherent/AdherentTab';
import AdminTab from '../components/Manage/Admin/AdminTab';

export default function SimpleTabs() {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.rootManage}>
      <AppBar position="static">
        <Tabs
          style={{ backgroundColor: '#016634' }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Typologie" />
          <Tab label="Civilité" />
          <Tab label="Statut" />
          <Tab label="Proximité" />
          <Tab label="Adherent" />
          <Tab label="Admin" />
        </Tabs>
      </AppBar>
      <ManageTab value={value} index={0}>
        <TypologyTab />
      </ManageTab>
      <ManageTab value={value} index={1}>
        <CivilityTab />
      </ManageTab>
      <ManageTab value={value} index={2}>
        <StatusTab />
      </ManageTab>
      <ManageTab value={value} index={3}>
        <DistanceTypeTab />
      </ManageTab>
      <ManageTab value={value} index={4}>
        <AdherentTab />
      </ManageTab>
      <ManageTab value={value} index={5}>
        <AdminTab />
      </ManageTab>
    </div>
  );
}
