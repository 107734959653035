import {
  SET_DISTANCE_TYPE,
  SET_DISTINCT_ZIPCODE,
  SET_EMPLOYEE_QUANTITY,
  SET_ADHERENTS,
  SET_STATUS,
  SET_TYPOLOGY,
  SET_CIVILITY,
  SET_STATUS_LIST,
  SET_TYPOLOGY_LIST,
  SET_DISTANCE_TYPE_LIST,
  SET_EMPLOYEE_QUANTITY_LIST,
  SET_DISTINCT_ZIPCODE_LIST,
} from './actionTypes';

export const setStatus = (value) => {
  return { type: SET_STATUS, payload: { id: value } };
};

export const setStatusList = (value) => {
  return { type: SET_STATUS_LIST, payload: { id: value } };
};

export const setTypology = (value) => {
  return { type: SET_TYPOLOGY, payload: { id: value } };
};

export const setTypologyList = (value) => {
  return { type: SET_TYPOLOGY_LIST, payload: { id: value } };
};

export const setDistanceType = (value) => {
  return { type: SET_DISTANCE_TYPE, payload: { id: value } };
};

export const setDistanceTypeList = (value) => {
  return { type: SET_DISTANCE_TYPE_LIST, payload: { id: value } };
};

export const setEmployeeQuantity = (value) => {
  return { type: SET_EMPLOYEE_QUANTITY, payload: { id: value } };
};

export const setCivility = (value) => {
  return { type: SET_CIVILITY, payload: { id: value } };
};

export const setEmployeeQuantityList = (value) => {
  return { type: SET_EMPLOYEE_QUANTITY_LIST, payload: { id: value } };
};
export const setAdherents = (value) => {
  return { type: SET_ADHERENTS, payload: { adherents: value } };
};

export const setDistinctZipCode = (value) => {
  return { type: SET_DISTINCT_ZIPCODE, payload: { id: value } };
};

export const setDistinctZipCodeList = (value) => {
  return { type: SET_DISTINCT_ZIPCODE_LIST, payload: { id: value } };
};
