import PropTypes, { arrayOf } from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { Title } from '@material-ui/icons';

function GenericTableHead({ headArray, className }) {
  // add an id to the array row
  const headConstructor = headArray.map((head, i) => {
    return { id: i + 1, label: head };
  });

  return (
    <TableHead>
      <TableRow className={className}>
        {headConstructor.map((title) => (
          <TableCell style={{ fontWeight: 'bold' }} key={Title.id}>
            {title.label.toUpperCase()}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// default props value
GenericTableHead.defaultProps = {
  headArray: [],
  className: '',
};

// Props validations
GenericTableHead.propTypes = {
  headArray: arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default GenericTableHead;
