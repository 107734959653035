import { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { putAdherentPassword } from '../../api/apiBack';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function CustomizedDialogs({ adherentId }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    putAdherentPassword(adherentId, { password: 'tree6clope' }).then(() => {
      handleClose();
    });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        style={{
          backgroundColor: '#70696d',
          borderRadius: '6px',
          padding: '5px 10px',
          color: 'white',
          width: '20%',
          margin: '80px',
        }}
        type="submit"
        onClick={handleClickOpen}
      >
        Réinitialiser le mot de passe
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{ width: '90%' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Voulez-vous réinitialiser le mot de passe pour cet adhérent ? (Mot de
          passe par défaut : tree6clope)
        </DialogTitle>
        <div style={{ textAlign: 'center' }}>
          <Button autoFocus onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button autoFocus onClick={handleSubmitPassword} color="primary">
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

CustomizedDialogs.propTypes = {
  adherentId: PropTypes.string.isRequired,
};
