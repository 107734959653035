import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAllEmployeeQuantity } from '../../api/apiBack';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const FormSelectEmployeeQuantity = ({ selected, onChange }) => {
  const classes = useStyles();
  const [employeeQuantity, newEmployeeQuantity] = useState([]);

  const employeeQuantitySuccess = (result) => {
    newEmployeeQuantity(result.data);
  };

  useEffect(() => {
    getAllEmployeeQuantity().then((results) =>
      employeeQuantitySuccess(results)
    );
  }, []);

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="employeeQuantityId">Nombre d employés</InputLabel>
      <Select
        style={{ width: '25%', marginBottom: '5px' }}
        variant="filled"
        name="employeeQuantityId"
        value={selected}
        onChange={onChange}
        className={classes.root}
      >
        {employeeQuantity.map((s) => (
          <option key={s.id} value={s.id}>{`${s.min} - ${s.max}`}</option>
        ))}
      </Select>
    </FormControl>
  );
};

FormSelectEmployeeQuantity.propTypes = {
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormSelectEmployeeQuantity;
