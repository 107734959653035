import { Container, Grid, Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useStyles from '../styles/CSS';
import PieGraphic from '../components/Graph/PieGraphic';
import PieGraphic2 from '../components/Graph/PieGraphic2';
import FormDate from '../components/Adherent/FormDate';
import BarGraphic from '../components/Graph/BarGraphic';

const Statistics = () => {
  const classes = useStyles();
  const [dateStart, setDateStart] = useState(
    new Date('2000-01-01').toISOString()
  );
  const [dateEnd, setDateEnd] = useState(new Date().toISOString());

  useEffect(() => {}, [dateEnd, dateStart]);
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={6}>
            <Paper>
              <PieGraphic dateStart={dateStart} dateEnd={dateEnd} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <Paper>
              <PieGraphic2 dateStart={dateStart} dateEnd={dateEnd} />
            </Paper>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormDate
                label="Date de départ"
                name="dateStart"
                disabled={false}
                selectedDate={dateStart}
                onChange={(e) => setDateStart(e.toISOString())}
              />
            </Grid>
            <Grid item xs={6}>
              <FormDate
                label="Date de fin"
                name="dateEnd"
                disabled={false}
                selectedDate={dateEnd}
                onChange={(e) => setDateEnd(e.toISOString())}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <BarGraphic />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Statistics;
