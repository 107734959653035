import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getContactByAdherentId } from '../../api/apiBack';

import '../../styles/ContactFormComponent.css';
import ContactFormCard from './ContactFormCard';
import TestContactLength from './TestContactsLength';

export default function ContactFormComponent({ id }) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [newEntrie, setNewEntrie] = useState(true);

  const fakeContact = {
    firstname: '',
    lastname: '',
    email: '',
    phone01: '',
    phone02: '',
    contactType: '',
    adherentId: parseInt(id, 10),
    civilityId: 1,
    isNew: 1,
  };

  useEffect(() => {
    getContactByAdherentId(id).then((results) => setContacts(results.data));

    setIsLoading(!isLoading);

    // if (contacts.length === 0) {
    //   setNewEntrie(!newEntrie);
    // }
  }, []);

  return (
    <div>
      {contacts.length !== 0 ? (
        <div style={{ display: 'flex' }}>
          <TestContactLength contacts={contacts} faker={fakeContact} />
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <ContactFormCard key={fakeContact.id} currentContact={fakeContact} />
          <ContactFormCard key={fakeContact.id} currentContact={fakeContact} />
        </div>
      )}
    </div>
  );
}

ContactFormComponent.propTypes = {
  id: PropTypes.string.isRequired,
};
