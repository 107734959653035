import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import store from './store/index';

import './index.css';
import Router from './Router';
// '#016634'
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#008A2E',
      main: '#008A2E',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#008A2E',
      main: '#008A2E',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
