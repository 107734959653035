import { useEffect, useState } from 'react';
import ContactFormCard from './ContactFormCard';

export default function TestContactLength(contacts) {
  const [currentContactsArray, setCurrentContactsArray] = useState(
    contacts.contacts
  );

  useEffect(() => {
    setCurrentContactsArray(contacts.contacts);
  }, [contacts]);

  return (
    <>
      {currentContactsArray.length === 2 ? (
        currentContactsArray.map((contact) => (
          <ContactFormCard key={contact.id} currentContact={contact} />
        ))
      ) : (
        <>
          <ContactFormCard currentContact={currentContactsArray[0]} />
          <ContactFormCard currentContact={contacts.faker} />
        </>
      )}
    </>
  );
}
