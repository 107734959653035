import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllStatus, postStatus, putStatus } from '../../api/apiBack';
import CreateLabelCard from './CreateLabelCard';
import ModifyLabelCard from './ModifyLabelCard';

const updateMess = 'Statut modifié';
const createMess = 'Statut créé';

function StatusTab() {
  const [tab, setTab] = useState([]);
  const [idField, setIdField] = useState('');
  const [modify, setModify] = useState(false);
  const [create, setCreate] = useState(false);
  const [isDisplay, setIsDisplay] = useState('none');

  useEffect(() => {
    getAllStatus().then((result) => setTab(result.data));
  }, []);

  useEffect(() => {
    setIsDisplay(create ? '' : 'none');
  }, [create]);

  const handleAdd = (elmt) => {
    setTab(tab.concat(elmt));
    postStatus(elmt)
      .then((results) => {
        setTab(tab.concat(results.data));
        toast.success(createMess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setIsDisplay('none');
  };

  const handlePut = (id, data) => {
    const toUpdate = { label: data.label };
    putStatus(data.id, toUpdate)
      .then(() => {
        setTab(tab.map((elmt) => (elmt.id === data.id ? data : elmt)));
        toast.success(updateMess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  function displayModify() {
    if (!modify) {
      setModify(!modify);
    }
  }

  const onClickRow = (e, a) => {
    displayModify();
    setIdField(a);
  };

  return (
    <div>
      <Table>
        <TableBody>
          {!modify ? (
            <>
              {tab.map((a) => (
                <TableRow hover key={a.id}>
                  <TableCell>{a.label}</TableCell>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={(event) => onClickRow(event, a)}
                  >
                    Modifier
                  </Button>
                </TableRow>
              ))}
              <TableRow style={{ display: `${isDisplay}` }}>
                <CreateLabelCard handleAdd={handleAdd} />
              </TableRow>
            </>
          ) : (
            <ModifyLabelCard
              idField={idField}
              modify={setModify}
              handlePut={handlePut}
            />
          )}
        </TableBody>
      </Table>

      <Button
        variant="contained"
        style={{ marginTop: '20px' }}
        type="button"
        onClick={() => setCreate(!create)}
      >
        creer un nouveau statut
      </Button>
      <ToastContainer />
    </div>
  );
}

export default StatusTab;
