import {
  Button,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function ModifyLabelCard({ idField, modify, handlePut }) {
  const [currentDatas, setCurrentDatas] = useState(idField);
  // const [currentId, setCurrentId] = useState({});

  // const currentDatasPromise = (result) => {
  //   setCurrentDatas(result.data);
  //   setCurrentId(result.data.id);
  // };

  // useEffect(() => {
  //   getTypologyById(idField.id).then((result) => currentDatasPromise(result));
  // }, [idField]);

  const handleField = (e) => {
    setCurrentDatas((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    handlePut(currentDatas.id, currentDatas);
    modify(false);
  };

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <TextField
            variant="filled"
            className="test"
            type="text"
            id="label"
            value={currentDatas.label}
            label="Modifier"
            name="label"
            onChange={handleField}
            style={{ width: '300px' }}
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <Button variant="contained" onClick={handleUpdate}>
          modifier
        </Button>
      </TableCell>
    </TableRow>
  );
}

ModifyLabelCard.propTypes = {
  idField: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  modify: PropTypes.func.isRequired,
  handlePut: PropTypes.func.isRequired,
};
