import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { getOneAdherent } from '../../api/apiBack';

import ContactFormComponent from '../Contact/ContactFormComponent';
import FormSelectStatus from './FormSelectStatus';
import FormSelectTypology from './FormSelectTypology';
import FormSelectDistanceType from './FormSelectDistanceType';
import FormSelectEmployeeQuantity from './FormSelectEmployeeQuantity';
import FormZipCode from './FormZipCode';
import FormDate from './FormDate';
import HarvestSiteForm from '../HarvestSiteForm/HarvestSiteForm';
import PopUpPassword from './PopUpPassword';
// import { buttonStyle, submitButtonStyle } from '../../css/formAdherentStyle';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));
function AdherentFormComponent({ id, axiosRequest }) {
  const classes = useStyles();
  const [adherent, setAdherent] = useState();
  const adherentStore = useSelector(
    (state) =>
      state.adherent.adherents.filter((a) => a.id === parseInt(id, 10))[0]
  );
  const [isSubmitDisplay, setIsSubmitDisplay] = useState(true);
  const [isContactDisplay, setIsContactDisplay] = useState(false);
  const [isHarvestSitetDisplay, setIsHarvestSitetDisplay] = useState(false);

  // get all datas for select choice
  useEffect(() => {
    setAdherent({
      sign: adherentStore.sign,
      legalEntity: adherentStore.legalEntity,
      streetNumber: adherentStore.streetNumber,
      streetName: adherentStore.streetName,
      postalBox: adherentStore.postalBox,
      primaryPhone: adherentStore.primaryPhone,
      subscribeNewsletter: adherentStore.subscribeNewsletter,
      email: adherentStore.email,
      active: adherentStore.active,
      seasonal: adherentStore.seasonal,
      publicAccess: adherentStore.publicAccess,
      terrace: adherentStore.terrace,
      terraceTableQuantity: adherentStore.terraceTableQuantity,
      renewalSubscription: adherentStore.renewalSubscription,
      statusId: adherentStore.statusId,
      typologyId: adherentStore.typologyId,
      zipCodeId: adherentStore.zipCodeId,
      distanceTypeId: adherentStore.distanceTypeId,
      employeeQuantityId: adherentStore.employeeQuantityId,
      godfatherId: adherentStore.godfatherId,
    });
  }, [adherentStore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosRequest(adherent);
  };

  // generic onChange for input text
  const handleField = (e) => {
    setAdherent((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  // generic onChange for input text
  const handleDatePicker = (e) => {
    setAdherent((state) => ({
      ...state,
      renewalSubscription: e.toISOString(),
    }));
  };

  // generic onChange for checkboxes
  const handleCheck = (e) => {
    setAdherent((state) => ({
      ...state,
      [e.target.name]: e.target.checked,
    }));
  };

  // form display buttons
  const contactDisplay = () => {
    setIsSubmitDisplay(!isSubmitDisplay);
    setIsContactDisplay(!isContactDisplay);
  };

  const harvestSiteDisplay = () => {
    setIsSubmitDisplay(!isSubmitDisplay);
    setIsHarvestSitetDisplay(!isHarvestSitetDisplay);
  };

  return adherent ? (
    <>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing="0" style={{ height: '60vh' }}>
            <Grid
              item
              xs="6"
              spacing="0"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
              }}
            >
              {/* input Sign */}
              <TextField
                variant="filled"
                type="text"
                label="Enseigne"
                value={adherent.sign}
                id="sign"
                name="sign"
                onChange={handleField}
              />

              {/* input legalEntity */}
              <TextField
                variant="filled"
                type="text"
                label="Statut legal"
                value={adherent.legalEntity}
                id="legalEntity"
                name="legalEntity"
                onChange={handleField}
              />

              {/* input streetNumber */}
              <TextField
                variant="filled"
                type="text"
                label="N° de rue"
                value={adherent.streetNumber}
                id="streetNumber"
                name="streetNumber"
                onChange={handleField}
              />

              {/* input streetName */}
              <TextField
                variant="filled"
                type="text"
                label="Nom de rue"
                value={adherent.streetName}
                id="streetName"
                name="streetName"
                onChange={handleField}
              />

              {/* input postalBox */}
              <TextField
                variant="filled"
                type="text"
                label="Boite postale"
                value={adherent.postalBox}
                id="postalBox"
                name="postalBox"
                onChange={handleField}
              />

              <FormZipCode
                selectedId={adherent.zipCodeId}
                onChange={handleField}
              />

              {/* add here zip and town */}
              <TextField
                style={{ width: '40%' }}
                variant="filled"
                type="text"
                id="primaryPhone"
                value={adherent.primaryPhone}
                name="primaryPhone"
                label="Téléphone"
                onChange={handleField}
              />

              <FormDate
                variant="filled"
                selectedDate={adherentStore.creationDate}
                label="Date d'inscription"
                name="creationDate"
                disabled
              />

              <FormDate
                variant="filled"
                selectedDate={adherent.renewalSubscription}
                label="Date de renouvellement"
                name="renewalSubscription"
                onChange={handleDatePicker}
              />

              <FormDate
                variant="filled"
                selectedDate={adherentStore.creationDate}
                label="Date d'expiration"
                name="expirationDate"
                disabled
              />

              <TextField
                className="test"
                type="text"
                id="email"
                value={adherent.email}
                label="Email"
                name="email"
                onChange={handleField}
              />
            </Grid>
            <Grid
              item
              xs="6"
              spacing="0"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="subscribeNewsletter"
                    checked={adherent.subscribeNewsletter}
                    onChange={handleCheck}
                  />
                }
                label="Newsletter"
                className={classes.root}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={adherent.active}
                    onChange={handleCheck}
                  />
                }
                label="Actif"
                className={classes.root}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="seasonal"
                    checked={adherent.seasonal}
                    onChange={handleCheck}
                  />
                }
                label="Saisonnier"
                className={classes.root}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="publicAccess"
                    checked={adherent.publicAccess}
                    onChange={handleCheck}
                  />
                }
                label="Accès publique"
                className={classes.root}
              />
              <Grid container spacing="0" style={{ justifySelf: 'start' }}>
                <Grid item xs="6">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="terrace"
                        checked={adherent.terrace}
                        onChange={handleCheck}
                      />
                    }
                    className={classes.root}
                    label="Terrasse"
                  />
                </Grid>
                <Grid item xs="6">
                  {adherent.terrace ? (
                    <TextField
                      variant="filled"
                      type="text"
                      id="terraceTableQuantity"
                      value={adherent.terraceTableQuantity}
                      label="Tables"
                      name="terraceTableQuantity"
                      onChange={handleField}
                    />
                  ) : (
                    <div />
                  )}
                </Grid>
              </Grid>

              <FormSelectStatus
                selected={adherent.statusId}
                onChange={handleField}
              />

              <FormSelectTypology
                selected={adherent.typologyId}
                onChange={handleField}
              />

              <FormSelectDistanceType
                selected={adherent.distanceTypeId}
                onChange={handleField}
              />

              <FormSelectEmployeeQuantity
                selected={adherent.distanceTypeId}
                onChange={handleField}
              />
            </Grid>
          </Grid>
        </FormControl>
        {isSubmitDisplay === true ? (
          <>
            <Button
              style={{
                margin: '50px',
                backgroundColor: '#70696d',
                color: 'white',
              }}
              type="submit"
              onClick={handleSubmit}
            >
              modifier adhérent
            </Button>
            <ToastContainer />
          </>
        ) : (
          ''
        )}
      </form>
      <PopUpPassword adherentId={id} />
      {/* display contact form */}
      <Button
        style={{
          margin: '40px',
          marginTop: '40px',
          padding: '5px',
          backgroundColor: 'lightgrey',
          color: 'black',
        }}
        onClick={contactDisplay}
      >
        modifier contact
      </Button>
      {isContactDisplay ? <ContactFormComponent id={id} /> : ''}
      {/* display harvestSite form */}
      <Button
        style={{
          margin: '40px',
          padding: '5px',
          backgroundColor: 'lightgrey',
          color: 'black',
        }}
        onClick={harvestSiteDisplay}
      >
        Gérer sites de collecte
      </Button>
      {isHarvestSitetDisplay ? <HarvestSiteForm adherentId={id} /> : ''}
    </>
  ) : (
    <div>loading</div>
  );
}

AdherentFormComponent.propTypes = {
  id: PropTypes.string.isRequired,
  axiosRequest: PropTypes.func.isRequired,
};

export default AdherentFormComponent;
