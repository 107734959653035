import axios from 'axios';
import { URL_API } from '../env';

const BASE_URL = `${URL_API}/`;

// axios auth config
const token = localStorage.getItem('TOKEN');
const config = {
  headers: {
    authorization: `Bearer ${token}`,
  },
};

/**
 *
 * @param {string} url route url
 */
function axiosGetPromise(url) {
  // const token = localStorage.getItem('TOKEN');
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // };

  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
}

function axiosGet(url) {
  // const token = localStorage.getItem('TOKEN');
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // };

  return axios.get(url, config);
}

function axiosPutPromise(url, datas) {
  // const token = localStorage.getItem('TOKEN');
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // };
  return new Promise((resolve, reject) => {
    axios
      .put(url, datas, config)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function axiosPut(url, datas) {
  // const token = localStorage.getItem('TOKEN');
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // };
  return axios.put(url, datas, config);
}

function axiosPostPromise(url, datas) {
  // const token = localStorage.getItem('TOKEN');
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // };
  return new Promise((resolve, reject) => {
    axios
      .post(url, datas, config)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function axiosPost(url, datas) {
  // const token = localStorage.getItem('TOKEN');
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // };
  return axios.post(url, datas, config);
}
// **************************************************
//    Status Queries
// **************************************************

export function getAllAdmin() {
  const url = BASE_URL.concat('admin');

  return axiosGet(url);
}

export function postAdmin(newAdmin) {
  const url = BASE_URL.concat('admin');

  return axiosPost(url, newAdmin);
}

// **************************************************
//    Status Queries
// **************************************************

export function getAllStatus() {
  const url = BASE_URL.concat('status');

  return axiosGet(url);
}

export function getStatusById(id) {
  const url = BASE_URL.concat(`status/${id}`);

  return axiosGetPromise(url);
}

export function putStatus(id, status) {
  const url = BASE_URL.concat(`status/${id}`);
  return axiosPutPromise(url, status);
}

export function postStatus(status) {
  const url = BASE_URL.concat(`status`);
  return axiosPostPromise(url, status);
}

// **************************************************
//    Adherent Queries
// **************************************************

export function getAllAdherents() {
  const url = BASE_URL.concat('adherent');

  return axiosGetPromise(url);
}

export function getOneAdherent(id) {
  const url = BASE_URL.concat(`adherent/${id}`);

  return axiosGetPromise(url);
}

export function AdherentsDistinctZipCode() {
  const url = BASE_URL.concat('adherent/distinctZipCode');

  return axiosGetPromise(url);
}
export function postAdherent(adherent) {
  const url = BASE_URL.concat(`adherent`);

  return axiosPostPromise(url, adherent);
}

// **************************************************
//    Civility Queries
// **************************************************

export function getAllCivility() {
  const url = BASE_URL.concat('civility');

  return axiosGet(url);
}

export function getCivilityById(id) {
  const url = BASE_URL.concat(`civility/${id}`);

  return axiosGet(url);
}

export function putCivility(id, civility) {
  const url = BASE_URL.concat(`civility/${id}`);
  return axiosPut(url, civility);
}

export function postCivility(civility) {
  const url = BASE_URL.concat(`civility`);
  return axiosPost(url, civility);
}

// **************************************************
//    Distance Type Queries
// **************************************************

export function getAllDistanceType() {
  const url = BASE_URL.concat('distanceType');

  return axiosGet(url);
}

export function getDistanceTypeById(id) {
  const url = BASE_URL.concat(`distanceType/${id}`);

  return axiosGetPromise(url);
}

export function putDistanceType(id, distancetype) {
  const url = BASE_URL.concat(`distanceType/${id}`);
  return axiosPut(url, distancetype);
}

export function postDistanceType(distancetype) {
  const url = BASE_URL.concat(`distanceType`);
  return axiosPost(url, distancetype);
}

// **************************************************
//    Typology Queries
// **************************************************

export function getAllTypology() {
  const url = BASE_URL.concat('typology');

  return axiosGet(url);
}

export function getTypologyById(id) {
  const url = BASE_URL.concat(`typology/${id}`);

  return axiosGetPromise(url);
}

export function putTypology(id, typology) {
  const url = BASE_URL.concat(`typology/${id}`);
  return axiosPut(url, typology);
}

export function postTypology(typology) {
  const url = BASE_URL.concat(`typology`);
  return axiosPost(url, typology);
}

export function getAllEmployeeQuantity() {
  const url = BASE_URL.concat('employeeQuantity');

  return axiosGet(url);
}

export function getAdherentZipCode(id) {
  const url = BASE_URL.concat(id);

  return axiosGetPromise(url);
}

export function putAdherent(id, adherent) {
  const url = BASE_URL.concat(`adherent/${id}`);
  return axiosPut(url, adherent);
}

export function putAdherentPassword(adherentId, password) {
  const url = BASE_URL.concat(`adherent/${adherentId}/password`);
  return axiosPut(url, password);
}

// **************************************************
//    Contact Queries
// **************************************************

export function getContactByAdherentId(adherentId) {
  const url = BASE_URL.concat(`adherent/${adherentId}/contacts`);
  return axiosGet(url);
}

export function putContact(id, contact) {
  const url = BASE_URL.concat(`contact/${id}`);
  return axiosPut(url, contact);
}

export function postContact(contact) {
  const url = BASE_URL.concat(`contact`);
  return axiosPost(url, contact);
}

// **************************************************
//    Harvest Sites
// **************************************************

export function getHarvestSitesbyId(id) {
  const url = BASE_URL.concat(`harvestsite/${id}`);

  return axiosGet(url);
}

export function getHarvestSitesByAdherentId(id, harvestId) {
  let url;
  if (harvestId) {
    url = BASE_URL.concat(`adherent/${id}/harvestSites?harvestId=${harvestId}`);
  } else {
    url = BASE_URL.concat(`adherent/${id}/harvestSites`);
  }
  return axiosGetPromise(url);
}

export function postHarvestSite(data) {
  const url = BASE_URL.concat(`harvestSite`);
  return axiosPostPromise(url, data);
}

export function putHarvestSite(id, data) {
  const url = BASE_URL.concat(`harvestSite/${id}`);
  return axiosPut(url, data);
}

// **************************************************
//   Harvest Points
// **************************************************
export function getAllHarvestPoints() {
  const url = BASE_URL.concat('harvestPoint');

  return axiosGetPromise(url);
}

export function postHarvestPoint(data) {
  const url = BASE_URL.concat(`harvestPoint`);
  return axiosPostPromise(url, data);
}

// **************************************************
//    Zip Codes
// **************************************************

export function getZipCodeById(id) {
  const url = BASE_URL.concat(`zipCode/${id}`);
  return axiosGet(url);
}

export function getCitiesByZipCode(adherentZipCode) {
  const url = BASE_URL.concat(`zipCode?cp=${adherentZipCode}`);

  return axiosGet(url);
}

// **************************************************
//    Articles
// **************************************************

export function getAllArticles() {
  const url = BASE_URL.concat('article');

  return axiosGetPromise(url);
}

// **************************************************
//    Collection Site States
// **************************************************

export function getAllCollectionSiteState() {
  const url = BASE_URL.concat('collectionSiteState');

  return axiosGetPromise(url);
}

// **************************************************
//    Collecte Weight
// **************************************************

export function postCollectWeight(data) {
  const url = BASE_URL.concat(`collectWeight`);
  return axiosPostPromise(url, data);
}

export function putCollectWeight(harvestId, harvestSiteId, data) {
  const url = BASE_URL.concat(`collectWeight/${harvestId}/${harvestSiteId}`);
  return axiosPutPromise(url, data);
}

export function getCollectWeightTotal() {
  const url = BASE_URL.concat(`collectWeight/totalWeight`);
  return axiosGet(url);
}

// **************************************************
//    Harvest
// **************************************************
export function postHarvest() {
  const url = BASE_URL.concat(`harvest`);
  return axiosPost(url, { date: new Date() });
}

// export function postHarvest() {
//   const url = BASE_URL.concat(`harvest`);
//   return axiosPostPromise(url, { date: new Date() });
// }

export function putHarvestClose(id) {
  const url = BASE_URL.concat(`harvest/${id}/close`);
  return axiosPut(url, {});
}

export function getHarvest() {
  const url = BASE_URL.concat('harvest?groupBy=isOpen');
  return axiosGet(url);
}

export function putDate(id, data) {
  const url = BASE_URL.concat(`harvest/${id}/modify`);
  return axiosPut(url, data);
}

// **************************************************
//    Stats
// **************************************************

export function getWeightByCriterias(
  // criteria= 'typology' / 'zipCode' / 'yearMonth
  criteria = '',
  dateStart = new Date('2000-01-01'),
  dateEnd = new Date()
) {
  let url = BASE_URL.concat('harvest');

  if (criteria !== '') {
    url += `?groupBy=${criteria}`;

    switch (criteria) {
      case 'typology':
      case 'zipCode':
        url += `&dateStart='${dateStart}'&dateEnd='${dateEnd}'`;
        break;
      default:
    }
  }

  return axiosGet(url);
}
