export const SET_STATUS = 'SET_STATUS';
export const SET_STATUS_LIST = 'SET_STATUS_LIST';
export const SET_TYPOLOGY = 'SET_TYPOLOGY';
export const SET_TYPOLOGY_LIST = 'SET_TYPOLOGY_LIST';
export const SET_CIVILITY = 'SET_CIVILITY';
export const SET_CIVILITY_LIST = 'SET_CIVILITY_LIST';
export const SET_DISTANCE_TYPE = 'SET_DISTANCE_TYPE';
export const SET_DISTANCE_TYPE_LIST = 'SET_DISTANCE_TYPE_LIST';
export const SET_EMPLOYEE_QUANTITY = 'SET_EMPLOYEE_QUANTITY';
export const SET_EMPLOYEE_QUANTITY_LIST = 'SET_EMPLOYEE_QUANTITY_LIST';
export const SET_DISTINCT_ZIPCODE = 'SET_DISTINCT_ZIPCODE';
export const SET_DISTINCT_ZIPCODE_LIST = 'SET_DISTINCT_ZIPCODE_LIST';

export const SET_ADHERENTS = 'SET_ADHERENTS';
