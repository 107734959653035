/* eslint-disable no-console */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllAdherents } from '../api/apiBack';
import GenericTableHead from '../components/GenericTable/GenericTableHead';
import AdherentFilter from '../components/Adherent/AdherentFilter';
import { setAdherents } from '../store/adherent/actionCreator';

const mapStateToProps = (state) => {
  return {
    status: state.adherent.status,
    typology: state.adherent.typology,
    employeeQuantity: state.adherent.employeeQuantity,
    distinctZipCode: state.adherent.distinctZipCode,
    distanceType: state.adherent.distanceType,
  };
};

const columns = [
  'Enseigne',
  'Statut légal',
  'Ville',
  'Actif',
  'Date de renouvellement',
];

function Adherent({
  status,
  typology,
  employeeQuantity,
  distinctZipCode,
  distanceType,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const adherents = useSelector((state) => state.adherent.adherents);
  const token = localStorage.getItem('TOKEN');

  const succesPromise = (result) => {
    dispatch(setAdherents(result.data));
  };
  const failPromise = () => {};

  useEffect(() => {
    const getDatas = async () => {
      const promiseAdherent = getAllAdherents();
      promiseAdherent.then(succesPromise, failPromise);
    };

    getDatas();
  }, [token]);

  const onClickRow = (e, id) => {
    history.push(`/home/adherent/${id}/edit`);
  };

  return (
    <>
      <AdherentFilter />
      <div style={{ height: 800, width: '100%' }}>
        <h1>Liste des Adhérents :</h1>
        <Table>
          {/* className = row className */}
          <GenericTableHead headArray={columns} className="" />
          <TableBody>
            {adherents
              .filter((f) => f.statusId === status || status === 0)
              .filter(
                (f) => f.zipCodeId === distinctZipCode || distinctZipCode === 0
              )
              .filter((t) => t.typologyId === typology || typology === 0)
              .filter(
                (t) => t.distanceTypeId === distanceType || distanceType === 0
              )
              .filter(
                (q) =>
                  q.employeeQuantityId === employeeQuantity ||
                  employeeQuantity === 0
              )
              .sort((b, c) => {
                return b.id + c.id;
              })
              .map((a) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => onClickRow(event, a.id)}
                    key={a.id}
                  >
                    <TableCell>{a.sign}</TableCell>
                    <TableCell>{a.legalEntity}</TableCell>
                    <TableCell>{a.zipCode.city}</TableCell>
                    <TableCell>{a.active ? 'oui' : 'non'}</TableCell>
                    <TableCell>
                      {a.renewalSubscription
                        ? new Date(a.renewalSubscription).toLocaleDateString()
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

Adherent.propTypes = {
  status: PropTypes.number.isRequired,
  typology: PropTypes.number.isRequired,
  employeeQuantity: PropTypes.number.isRequired,
  distinctZipCode: PropTypes.number.isRequired,
  distanceType: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Adherent);
