import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { getAllAdherents, getCollectWeightTotal } from '../api/apiBack';
import { setAdherents } from '../store/adherent/actionCreator';
import pictogrammePoids from '../images/pictogrammePoids.png';
import logoMegot from '../images/logoMegot.png';
import logoAdherents from '../images/logoAdherents.png';
import pictogrammeCanard from '../images/pictogrammeCanard.png';

function Accueil() {
  const useStyles = makeStyles({
    subtitle: {
      textAlign: 'center',
      fontSize: '1.2rem',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    picto: {
      width: '70px',
      height: '70px',
    },
    card: {
      textAlign: 'center',
      padding: '10px',
      width: '250px',
      marginBottom: '10px',
    },
    number: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      marginBottom: '5px',
    },
    banner: {
      backgroundColor: '#1E6634',
      borderRadius: '10px 0',
      color: 'white',
      height: '30px',
      margin: 0,
      paddingTop: '5px',
    },
    underBanner: {
      marginTop: 0,
    },
  });

  const dispatch = useDispatch();
  const mainData = useSelector((state) => state.adherent.adherents);
  const [sum, setSum] = useState({});
  const token = useSelector((state) => state.admin.token);
  // const localToken = localStorage.getItem('TOKEN');

  const succesPromise = (result) => {
    dispatch(setAdherents(result.data));
  };
  const failPromise = () => {};

  useEffect(() => {
    if (token) {
      const getDatas = async () => {
        const promiseAdherent = getAllAdherents();
        promiseAdherent.then(succesPromise, failPromise);
      };
      getDatas();
      getCollectWeightTotal().then((res) => {
        setSum(res.data.sum);
      });
    }
  }, [token]);

  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid style={{ textAlign: 'center' }} xs={12}>
          <p className={classes.subtitle}>
            Bienvenue {localStorage.getItem('ADMIN')}
          </p>
        </Grid>
        <Grid
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          <Paper className={classes.card}>
            <img
              src={logoAdherents}
              alt="Pictogramme adhérents"
              className={classes.picto}
            />
            <p className={classes.number}>{mainData.length}</p>
            <p className={classes.banner}>ADHÉRENTS</p>
            <p className={classes.underBanner}>au total</p>
          </Paper>
          <Paper className={classes.card}>
            <img
              src={logoMegot}
              alt="Pictogramme poids"
              className={classes.picto}
            />
            <p className={classes.number}>{Math.round(sum.weight / 0.32)}</p>
            <p className={classes.banner}>MÉGOTS</p>
            <p className={classes.underBanner}>collectés</p>
          </Paper>

          <Paper className={classes.card}>
            <img
              src={pictogrammePoids}
              alt="Pictogramme poids"
              className={classes.picto}
            />
            <p className={classes.number}>{(sum.weight / 1000).toFixed(2)}</p>
            <p className={classes.banner}>KILOS</p>
            <p className={classes.underBanner}>
              de mégots collectés et recyclés
            </p>
          </Paper>

          <Paper className={classes.card}>
            <img
              src={pictogrammeCanard}
              alt="Pictogramme poids"
              className={classes.picto}
            />
            <p className={classes.number}>
              {((sum.weight / 0.32) * 0.005).toFixed(2)}
            </p>
            <p className={classes.banner}>m3 </p>
            <p className={classes.underBanner}>
              d&apos;eau potentiellement épargnés
            </p>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Accueil;
