import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAllTypology } from '../../api/apiBack';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const FormSelectTypology = ({ selected, onChange }) => {
  const classes = useStyles();
  const [typology, setTypology] = useState([]);

  const typologySuccess = (result) => {
    setTypology(result.data);
  };

  useEffect(() => {
    getAllTypology().then((results) => typologySuccess(results));
  }, []);

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="typologyId">Typologie</InputLabel>
      <Select
        style={{ width: '25%', marginBottom: '5px' }}
        variant="filled"
        name="typologyId"
        value={selected}
        onChange={onChange}
      >
        <option value="0">Tous</option>
        {typology.map((s) => (
          <option key={s.id} value={s.id}>
            {s.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

FormSelectTypology.propTypes = {
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormSelectTypology;
