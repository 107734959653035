import { Button, TableCell, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getHarvestSitesbyId,
  getZipCodeById,
  putHarvestSite,
} from '../../api/apiBack';
import HarvestPointModal from './HarvestPointModal';

/**
 *
 * @param {*} param0
 */
const HarvestSiteRow = ({ id, label, streetNumber, streetName, zipCodeId }) => {
  const [zipCode, setZipCode] = useState({});
  const [open, setOpen] = useState(false);
  const zipCodeSuccessPromise = (result) => {
    setZipCode(result.data);
  };
  const [harvestSite, setHarvestSite] = useState([]);
  // const [buttonDisplayed, setButtonDisplayed] = useState('');

  const failPromise = (error) => {
    throw new Error(error.message);
  };

  function whatDisplayed(toto) {
    if (toto === false) {
      return 'activer';
    }
    return 'désactiver';
  }

  useEffect(() => {
    const getDatas = async () => {
      const promiseZipCode = getZipCodeById(zipCodeId);
      promiseZipCode.then(zipCodeSuccessPromise, failPromise);
    };

    getDatas();
    getHarvestSitesbyId(id).then((result) => setHarvestSite(result.data));
  }, []);

  const handleModal = () => {
    setOpen(true);
  };

  const handleIsActif = () => {
    const data = {
      ...harvestSite,
      isActif: !harvestSite.isActif,
    };
    setHarvestSite(data);
    putHarvestSite(harvestSite.id, data);
  };

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '20%' }}>{label}</TableCell>
        <TableCell style={{ width: '20%' }}>{streetNumber}</TableCell>
        <TableCell style={{ width: '20%' }}>{streetName}</TableCell>
        <TableCell style={{ width: '20%' }}>{zipCode.zipCode}</TableCell>
        <TableCell style={{ width: '20%' }}>{zipCode.city}</TableCell>
        <TableCell>
          <div style={{ display: 'flex' }}>
            <Button
              style={{
                marginRight: '8px',
                width: '100%',
                maxWidth: '120px',
              }}
              variant="contained"
              onClick={() => handleIsActif()}
            >
              {whatDisplayed(harvestSite.isActif)}
            </Button>
            <Button
              style={{ width: '100%', maxWidth: '5px' }}
              variant="contained"
              onClick={handleModal}
              title="Ajouter un point de collecte"
            >
              +
            </Button>
          </div>
        </TableCell>
      </TableRow>

      <HarvestPointModal
        mustOpen={open}
        harvestSiteId={id}
        mustClose={() => setOpen(false)}
      />
    </>
  );
};

HarvestSiteRow.propTypes = {
  label: PropTypes.string.isRequired,
  streetNumber: PropTypes.string.isRequired,
  streetName: PropTypes.string.isRequired,
  zipCodeId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};
export default HarvestSiteRow;
