import { Route, useLocation } from 'react-router-dom';
import { CssBaseline, Container, Grid, Paper } from '@material-ui/core';

import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';

import Adherent from '../pages/Adherent';
import Accueil from '../pages/Accueil';
import Manage from '../pages/Manage';
import AdherentFormEdit from '../pages/AdherentFormEdit';
import Map from '../Map/Map';

import useStyles from '../styles/CSS';
import Statistics from '../pages/Statistics';
import Collecte from '../pages/Collecte';

const routes = [
  { path: '/home/accueil', name: 'Accueil', Component: Accueil },

  { path: '/home/adherents', name: 'Adherent', Component: Adherent },
  {
    path: '/home/adherent/:id/edit',
    name: 'AdherentFormEdit',
    Component: AdherentFormEdit,
  },

  { path: '/home/manage', name: 'Manage', Component: Manage },

  { path: '/home/donnees', name: 'Stats', Component: Statistics },

  { path: '/home/map', name: 'Map', Component: Map },
  { path: '/home/collecte', name: 'Collecte', Component: Collecte },
];

function Dashboard() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <NavBar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={path} location={location}>
                      <Component />
                    </Route>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <div className={classes.appBarSpacer} />
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
