import { useEffect, useState } from 'react';
import { Button, Grid, Table, TableBody } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import FormSelectAdherent from './FormSelectAdherent';
import {
  getHarvest,
  getHarvestSitesByAdherentId,
  postHarvest,
  putDate,
  putHarvestClose,
} from '../../api/apiBack';
import GenericTableHead from '../GenericTable/GenericTableHead';
import FormCollecteRow from './formCollecteRow';
import FormDate from '../Adherent/FormDate';

// array of table columns name
const columns = ['Label', 'Adresse', 'Poids', 'Action'];

/**
 *
 */
const FormCollecte = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [harvest, setHarvest] = useState();
  const [adherentId, setAdherentId] = useState(0);
  const [harvestSites, setHarvestSites] = useState([]);
  const history = useHistory();

  const promiseHarvestSucces = (results) => {
    if (results) {
      setHarvest(results);
      setSelectedDate(results.date);
    } else {
      postHarvest().then((result) => setHarvest(result.data)); //  new date is allready inquire in postHarvest query
    }
  };

  const promiseHarvestSitesSuccess = (results) => {
    setHarvestSites(results.data[0].harvestSite);
  };
  const promiseFail = () => {};

  useEffect(() => {
    // get open harvest or create one
    getHarvest().then((result) => promiseHarvestSucces(result.data));
  }, []);

  useEffect(() => {
    if (adherentId !== 0) {
      // get harvest sites for selected adherent
      const harvestSitesPromise = getHarvestSitesByAdherentId(
        adherentId,
        harvest.id
      );
      harvestSitesPromise.then(promiseHarvestSitesSuccess, promiseFail);
    }
  }, [adherentId, harvest]);

  const handleClose = () => {
    putHarvestClose(harvest.id).then(() => {
      setHarvest({});
      setAdherentId(0);
      setHarvestSites([]);
      history.push('/home/accueil');
    });
  };

  const handlePicker = (e) => {
    setHarvest((state) => ({
      ...state,
      date: e.toISOString(),
    }));
    setSelectedDate(e.toISOString());
    putDate(harvest.id, { date: e.toISOString() });
  };

  return (
    <>
      {harvest ? (
        <Grid container>
          <Grid item sm={6}>
            <h1>
              Collecte n° {harvest.id} du{' '}
              {new Date(harvest.date).toLocaleDateString()}
            </h1>
          </Grid>
          <Grid item sm={6}>
            <FormDate
              selectedDate={selectedDate}
              onChange={handlePicker}
              label="Nouvelle date"
              name="date"
              disabled={false}
            />
          </Grid>
        </Grid>
      ) : (
        <h1>Collecte n° du </h1>
      )}
      <FormSelectAdherent setAdherentId={setAdherentId} />
      <Table>
        <GenericTableHead headArray={columns} />
        <TableBody>
          {harvestSites.map((h) => {
            return (
              <FormCollecteRow
                key={h.id}
                harvestSiteId={h.id}
                label={h.label}
                adress={h.streetNumber.concat(', ', h.streetName)}
                harvestId={harvest.id}
                weightProps={h.harvest[0] ? h.harvest[0].weight : 0}
              />
            );
          })}
        </TableBody>
      </Table>
      <Button variant="contained" onClick={handleClose}>
        Fermer la collecte
      </Button>
    </>
  );
};

export default FormCollecte;
