import Paper from '@material-ui/core/Paper';
import {
  Chart,
  Legend,
  PieSeries,
  Tooltip,
  Title,
} from '@devexpress/dx-react-chart-material-ui'; // Legend, // BarSeries, // ValueAxis, // ArgumentAxis,
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
import PropTypes from 'prop-types';

const PieGraphicComponent = ({ datas }) => {
  return (
    <Paper>
      <Chart data={datas}>
        <PieSeries valueField="weight" argumentField="Typologie" scaleName="" />
        <Title text="Poids par typologie" />
        <EventTracker />
        <Tooltip />
        <Animation />
        <Legend />
      </Chart>
    </Paper>
  );
};

PieGraphicComponent.defaultProps = {
  datas: [],
};

PieGraphicComponent.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.object),
};
export default PieGraphicComponent;
