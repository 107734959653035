import { toast, ToastContainer } from 'react-toastify';
import { Grid, TextField, FormControl, Button } from '@material-ui/core';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { putContact, postContact } from '../../api/apiBack';

import FormCivility from './FormCivility';

import 'react-toastify/dist/ReactToastify.css';

export default function ContactFormCard(currentContact) {
  const updateMess = 'Contact modifié';
  const createMess = 'Contact créé';
  const [contact, setContact] = useState(currentContact);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    setContact(currentContact.currentContact);
  }, [currentContact]);

  const handleUpdate = (e) => {
    e.preventDefault();
    putContact(contact.id, contact)
      .then(() => {
        toast.success(updateMess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleCreate = (e) => {
    e.preventDefault();
    postContact(contact)
      .then(() => {
        setUpdate(!update);
        toast.success(createMess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleField = (e) => {
    setContact((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <form onSubmit={(handleCreate, handleUpdate)} style={{ width: '100%' }}>
        <FormControl style={{ margin: '80px' }}>
          <FormCivility
            selectedCivId={contact.civilityId}
            onChange={handleField}
          />
          <Grid container spacing="0">
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="firstname"
              value={contact.firstname}
              label="Prénom"
              name="firstname"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="lastname"
              value={contact.lastname}
              label="Nom de Famille"
              name="lastname"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="email"
              value={contact.email}
              label="Email"
              name="email"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="phone01"
              value={contact.phone01}
              label="Téléphone 1"
              name="phone01"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingRight: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="phone02"
              value={contact.phone02}
              label="Téléphone 2"
              name="phone02"
              onChange={handleField}
            />
            <TextField
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
              }}
              variant="filled"
              className="test"
              type="text"
              id="contactType"
              value={contact.contactType}
              label="Fonction dans l'établissement"
              name="contactType"
              onChange={handleField}
            />
          </Grid>
        </FormControl>
      </form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {contact.isNew !== undefined ? (
          <>
            <Button variant="contained" type="submit" onClick={handleCreate}>
              creer
            </Button>
            <ToastContainer />
          </>
        ) : (
          <>
            <Button
              style={{
                padding: '5px',
                backgroundColor: '#70696d',
                color: 'white',
              }}
              variant="contained"
              type="submit"
              onClick={handleUpdate}
            >
              modifier
            </Button>
            <ToastContainer />
          </>
        )}
      </div>
    </div>
  );
}

ContactFormCard.propTypes = {
  currentContact: propTypes.shape({
    id: propTypes.number.isRequired,
    firstname: propTypes.string.isRequired,
    lastname: propTypes.string.isRequired,
    email: propTypes.string.isRequired,
    phone01: propTypes.string.isRequired,
    phone02: propTypes.string.isRequired,
    contactType: propTypes.string.isRequired,
    civilityId: propTypes.number.isRequired,
    adherentId: propTypes.number.isRequired,
  }).isRequired,
  // entrie: propTypes.number.isRequired,
};
