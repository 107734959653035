import { Button, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const HarvestPointRow = ({
  // id,
  latitude,
  longitude,
  collectionSiteStateId,
}) => {
  const css = useSelector(
    (state) => state.collectionSiteState.collectionSiteStates
  );

  return (
    <TableRow>
      <TableCell>{latitude}</TableCell>
      <TableCell>{longitude}</TableCell>
      <TableCell>
        {css ? css.filter((c) => c.id === collectionSiteStateId)[0].label : ''}
      </TableCell>
      <TableCell>
        <Button variant="contained" disabled="true">
          Désactiver
        </Button>
      </TableCell>
    </TableRow>
  );
};

HarvestPointRow.propTypes = {
  // id: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  collectionSiteStateId: PropTypes.number.isRequired,
};

export default HarvestPointRow;
