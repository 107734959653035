import Paper from '@material-ui/core/Paper';
import {
  Chart,
  Tooltip,
  Title,
  BarSeries,
  ArgumentAxis,
} from '@devexpress/dx-react-chart-material-ui'; // Legend, // BarSeries, // ValueAxis, // ArgumentAxis,
import { Animation, EventTracker, ValueAxis } from '@devexpress/dx-react-chart';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const monthsFr = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const uniqueYear = (array) => {
  return array
    .map((elmt) => elmt.annee)
    .filter((value, index, self) => self.indexOf(value) === index);
};

const selectedDataFunc = (datas, e) => {
  return datas.filter((a) => a.annee === parseInt(e, 10));
};

const addMonthFrToDatas = (datas) => {
  return datas.map((elmt) => {
    return {
      ...elmt,
      moisFr: monthsFr.filter((m, i) => elmt.mois === i + 1)[0],
    };
  });
};

const BarGraphicComponent = ({ datas }) => {
  const [datasFr, setDatasFr] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [years, setYears] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    const sortdData = datas.sort((a, b) => a.mois - b.mois);
    setDatasFr(addMonthFrToDatas(sortdData));
    const distinctYears = uniqueYear(datas).reverse();
    setSelectedYear(distinctYears[0]);
    setYears(distinctYears);
  }, [datas]);

  useEffect(() => {
    setSelectedData(selectedDataFunc(datasFr, selectedYear));
  }, [datasFr]);

  const handleChangeYear = (e) => {
    setSelectedYear(e.target.value);

    setSelectedData(selectedDataFunc(datasFr, e.target.value, 10));
  };

  return (
    <Paper>
      <Chart data={selectedData}>
        <ArgumentAxis />
        <ValueAxis max={7} />
        <BarSeries valueField="weight" argumentField="moisFr" />
        <Title text="Poids global par mois de l'année" />
        <Animation />
        <EventTracker />
        <Tooltip />
        <Animation />
      </Chart>
      <select
        style={{ width: '100px', margin: '10px' }}
        onChange={handleChangeYear}
        value={selectedYear}
      >
        {years ? (
          years.map((elmt) => {
            return (
              <option key={`${elmt}a`} value={elmt}>
                {elmt}
              </option>
            );
          })
        ) : (
          <option>Année</option>
        )}
      </select>
    </Paper>
  );
};

BarGraphicComponent.defaultProps = {
  datas: [],
};

BarGraphicComponent.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.object),
};
export default BarGraphicComponent;
