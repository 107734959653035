import PropTypes from 'prop-types';
import { Button, TableCell, TableRow, TextField } from '@material-ui/core';
import { useState } from 'react';
import { postCollectWeight, putCollectWeight } from '../../api/apiBack';

const FormCollecteRow = ({
  harvestSiteId,
  label,
  adress,
  harvestId,
  weightProps,
}) => {
  const [weight, setWeight] = useState(weightProps);
  const [enableValid, setEnableValid] = useState(true);

  const promiseUpdateWeightSuccess = () => {
    setEnableValid(false);
  };
  const promiseSaveWeightSuccess = () => {
    setEnableValid(false);
  };

  const promiseFail = () => {};

  const handleCreate = (e) => {
    e.preventDefault();
    const collectWeight = {
      harvestId,
      harvestSiteId,
      weight,
    };
    const promiseSaveWeight = postCollectWeight(collectWeight);
    promiseSaveWeight.then(promiseSaveWeightSuccess, promiseFail);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const collectWeight = {
      weight,
    };
    const promiseUpdateWeight = putCollectWeight(
      harvestId,
      harvestSiteId,
      collectWeight
    );
    promiseUpdateWeight.then(promiseUpdateWeightSuccess, promiseFail);
  };

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>{adress}</TableCell>
      <TableCell>
        <TextField
          type="text"
          label="Poids en grammes"
          value={weight}
          id="weight"
          name="weight"
          onChange={(e) => setWeight(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          onClick={
            enableValid && weightProps === 0 ? handleCreate : handleUpdate
          }
        >
          {enableValid && weightProps === 0 ? 'Valider' : 'Modifier'}
        </Button>
      </TableCell>
    </TableRow>
  );
};

FormCollecteRow.propTypes = {
  label: PropTypes.isRequired,
  adress: PropTypes.isRequired,
  harvestId: PropTypes.isRequired,
  harvestSiteId: PropTypes.isRequired,
  weightProps: PropTypes.isRequired,
};
export default FormCollecteRow;
