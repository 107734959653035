import { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
  AdherentsDistinctZipCode,
  getAllEmployeeQuantity,
  getAllStatus,
  getAllTypology,
  getAllDistanceType,
} from '../../api/apiBack';

import {
  setStatus,
  setTypology,
  setEmployeeQuantity,
  setDistinctZipCode,
  setDistanceType,
  setEmployeeQuantityList,
  setTypologyList,
  setDistanceTypeList,
  setStatusList,
  setDistinctZipCodeList,
} from '../../store/adherent/actionCreator';

import './adherentFilter.css';

const useStyles = makeStyles({
  select: {
    width: '20%',
  },
});

function AdherentFilter() {
  const classes = useStyles();
  const statusList = useSelector((state) => state.adherent.statusList);
  const typologyList = useSelector((state) => state.adherent.typologyList);
  const distinctZipCodeList = useSelector(
    (state) => state.adherent.distinctZipCodeList
  );
  const employeeQuantityList = useSelector(
    (state) => state.adherent.employeeQuantityList
  );
  const distanceTypeList = useSelector(
    (state) => state.adherent.distanceTypeList
  );
  const dispatch = useDispatch();

  // reset selected
  const reset = () => {
    dispatch(setEmployeeQuantity(0));
    dispatch(setDistinctZipCode(0));
    dispatch(setDistanceType(0));
    dispatch(setTypology(0));
    dispatch(setStatus(0));
  };

  useEffect(() => {
    getAllStatus().then((result) => {
      dispatch(setStatusList(result.data));
    });
    getAllTypology().then((result) => {
      dispatch(setTypologyList(result.data));
    });
    getAllEmployeeQuantity().then((result) => {
      dispatch(setEmployeeQuantityList(result.data));
    });
    AdherentsDistinctZipCode().then((result) => {
      dispatch(setDistinctZipCodeList(result.data));
    });
    getAllDistanceType().then((result) => {
      dispatch(setDistanceTypeList(result.data));
    });

    return () => reset();
  }, []);

  return (
    <div>
      <FormControl className={classes.select}>
        <InputLabel id="demo-simple-select-label">Statut</InputLabel>
        <Select
          style={{ marginRight: '5px', marginBottom: '5px' }}
          variant="filled"
          name="status"
          id="status"
          onChange={(e) => {
            dispatch(setStatus(parseInt(e.target.value, 10)));
          }}
        >
          <option value="0">Tous</option>
          {statusList.map((s) => (
            <option key={s.label} value={s.id}>
              {s.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.select}>
        <InputLabel id="demo-simple-select-label">Typologie</InputLabel>
        <Select
          style={{ marginRight: '5px', marginBottom: '5px' }}
          variant="filled"
          name="typology"
          id="typology"
          onChange={(e) => {
            dispatch(setTypology(parseInt(e.target.value, 10)));
          }}
        >
          <option value="0">Tous</option>
          {typologyList.map((t) => (
            <option key={t.label} value={t.id}>
              {t.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.select}>
        <InputLabel id="demo-simple-select-label">Ville</InputLabel>
        <Select
          style={{ marginRight: '5px', marginBottom: '5px' }}
          variant="filled"
          name="distinctZipCode"
          id="distinctZipCode"
          onChange={(e) => {
            dispatch(setDistinctZipCode(parseInt(e.target.value, 10)));
          }}
        >
          <option value="0">Tous</option>
          {distinctZipCodeList.map((t) => (
            <option key={t.zipCode.city} value={t.zipCode.inseeId}>
              {t.zipCode.city}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.select}>
        <InputLabel id="demo-simple-select-label">Quantité employés</InputLabel>
        <Select
          style={{ marginRight: '5px', marginBottom: '5px' }}
          variant="filled"
          name="employeeQuantity"
          id="employeeQuantity"
          onChange={(e) => {
            dispatch(setEmployeeQuantity(parseInt(e.target.value, 10)));
          }}
        >
          <option value="0">Tous</option>
          {employeeQuantityList.map((q) => (
            <option key={q.max} value={q.id}>{`${q.min} - ${q.max}`}</option>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.select}>
        <InputLabel id="demo-simple-select-label">Proximité</InputLabel>
        <Select
          style={{ marginRight: '5px', marginBottom: '5px' }}
          variant="filled"
          name="distanceType"
          id="distanceType"
          onChange={(e) => {
            dispatch(setDistanceType(parseInt(e.target.value, 10)));
          }}
        >
          <option value="0">Tous</option>
          {distanceTypeList.map((q) => (
            <option value={q.label}>{`${q.label}`}</option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default AdherentFilter;
