import { Table, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getHarvestSitesByAdherentId } from '../../api/apiBack';
import GenericTableHead from '../GenericTable/GenericTableHead';
import HarvestSiteRow from './HarvestSiteRow';
import HarvestSiteRowCreate from './HarvestSiteRowCreate';

// header column names for table component
const columns = [
  'Label',
  'Numéro De Rue',
  'Nom De Rue',
  'Code Postale',
  'Ville',
  'Action',
];

const HarvestSiteForm = ({ adherentId }) => {
  const [harvestSite, setHarvestSite] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const harvestSiteSuccessPromise = (result) => {
    setHarvestSite(result.data[0].harvestSite);
  };

  const failPromise = () => {};

  const addRow = (data) => {
    setHarvestSite(harvestSite.concat(data));
  };

  useEffect(() => {
    const getDatas = async () => {
      const promiseHarvestSite = getHarvestSitesByAdherentId(adherentId);
      promiseHarvestSite.then(harvestSiteSuccessPromise, failPromise);
    };

    getDatas();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Table>
        <GenericTableHead headArray={columns} className="" />
        <TableBody>
          {harvestSite.map((h) => {
            return (
              <HarvestSiteRow
                key={h.label}
                id={h.id}
                label={h.label}
                streetNumber={h.streetNumber}
                streetName={h.streetName}
                zipCodeId={h.zipCodeId}
              />
            );
          })}
          <HarvestSiteRowCreate adhId={adherentId} addRow={addRow} />
        </TableBody>
      </Table>
    </form>
  );
};

HarvestSiteForm.propTypes = {
  adherentId: PropTypes.number.isRequired,
};
export default HarvestSiteForm;
