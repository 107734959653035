import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getCitiesByZipCode, postHarvestSite } from '../../api/apiBack';
// import buttonStyle from '../../css/formHarvestSiteRowStyle';

import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

/**
 *
 * @param {*} param0
 */
const HarvestSiteRowCreate = ({ adhId, addRow }) => {
  const message = 'Site de collecte ajouté';
  const initHarvestSite = () => {
    return {
      label: '',
      streetNumber: '',
      streetName: '',
      zipCodeId: 0,
      adherentId: adhId,
    };
  };

  const classes = useStyles();
  const [harvestSite, setHarvestSite] = useState(initHarvestSite());
  const [codePostal, setCodePostal] = useState('');
  const [citySelectValue, setCitySelectValue] = useState(0);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setHarvestSite({
      label: '',
      streetNumber: '',
      streetName: '',
      zipCodeId: 0,
      adherentId: adhId,
    });
  }, [adhId]);

  // affect fields values to harvestSiteState
  const handleField = (e) => {
    setHarvestSite((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const failPromise = (error) => {
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return error;
  };

  const postSuccess = (result) => {
    addRow(result.data);
    setHarvestSite(initHarvestSite());
    setCodePostal('');
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postRequestHarvestSite = postHarvestSite(harvestSite);
    postRequestHarvestSite.then(postSuccess, failPromise);
  };

  const citiesSuccess = (result) => {
    setCities(result.data);
  };

  useEffect(() => {
    if (cities.length > 0) {
      setCitySelectValue(cities[0].inseeId);
    }
  }, [cities]);

  // find cities when zipCode length ===5
  const handleZipCode = (e) => {
    if (e.target.value.length <= 5) {
      setCodePostal(e.target.value);
    }

    if (e.target.value.length === 5) {
      const citiesPromise = getCitiesByZipCode(e.target.value);
      citiesPromise.then(citiesSuccess, failPromise);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          variant="filled"
          style={{ width: '100%' }}
          type="text"
          label="Label"
          value={harvestSite.label}
          id="label"
          name="label"
          onChange={handleField}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="filled"
          style={{ width: '100%' }}
          type="text"
          label="N°rue"
          value={harvestSite.streetNumber}
          id="streetNumber"
          name="streetNumber"
          onChange={handleField}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="filled"
          style={{ width: '100%' }}
          type="text"
          label="Nom de rue"
          value={harvestSite.streetName}
          id="streetName"
          name="streetName"
          onChange={handleField}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="filled"
          style={{ width: '100%' }}
          type="text"
          label="Code Postal"
          value={codePostal}
          id="codePostal"
          name="codePostal"
          onChange={handleZipCode}
        />
      </TableCell>
      <TableCell>
        {/* select City */}
        <FormControl className={classes.root}>
          <InputLabel htmlFor="zipCodeId">City</InputLabel>
          <Select
            variant="filled"
            style={{ width: '100%' }}
            name="zipCodeId"
            value={harvestSite ? harvestSite.zipCodeId : citySelectValue}
            onChange={handleField}
          >
            {cities.map((s) => (
              <option key={s.inseeId} value={s.inseeId}>
                {s.city}
              </option>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <Button
          style={{
            width: '100%',
            backgroundColor: '#70696d',
            color: 'white',
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          Ajouter
        </Button>
        <ToastContainer />
      </TableCell>
    </TableRow>
  );
};
HarvestSiteRowCreate.propTypes = {
  adhId: PropTypes.number.isRequired,
  addRow: PropTypes.func.isRequired,
};
export default HarvestSiteRowCreate;
