import {
  SET_ADHERENTS,
  SET_DISTANCE_TYPE,
  SET_DISTANCE_TYPE_LIST,
  SET_DISTINCT_ZIPCODE,
  SET_DISTINCT_ZIPCODE_LIST,
  SET_EMPLOYEE_QUANTITY,
  SET_EMPLOYEE_QUANTITY_LIST,
  SET_STATUS,
  SET_STATUS_LIST,
  SET_TYPOLOGY,
  SET_TYPOLOGY_LIST,
} from './actionTypes';

const initialState = {
  distinctZipCode: 0,
  status: 0,
  typology: 0,
  employeeQuantity: 0,
  distanceType: 0,
  statusList: [],
  typologyList: [],
  employeeQuantityList: [],
  distanceTypeList: [],
  distinctZipCodeList: [],
  adherents: [],
};

const adherentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISTINCT_ZIPCODE:
      return { ...state, distinctZipCode: action.payload.id };
    case SET_DISTINCT_ZIPCODE_LIST:
      return { ...state, distinctZipCodeList: action.payload.id };
    case SET_STATUS:
      return { ...state, status: action.payload.id };
    case SET_TYPOLOGY:
      return { ...state, typology: action.payload.id };
    case SET_EMPLOYEE_QUANTITY:
      return { ...state, employeeQuantity: action.payload.id };
    case SET_DISTANCE_TYPE:
      return { ...state, distanceType: action.payload.id };
    case SET_STATUS_LIST:
      return { ...state, statusList: action.payload.id };
    case SET_TYPOLOGY_LIST:
      return { ...state, typologyList: action.payload.id };
    case SET_EMPLOYEE_QUANTITY_LIST:
      return { ...state, employeeQuantityList: action.payload.id };
    case SET_DISTANCE_TYPE_LIST:
      return { ...state, distanceTypeList: action.payload.id };
    case SET_ADHERENTS:
      return { ...state, adherents: action.payload.adherents };
    default:
      return state;
  }
};

export default adherentReducer;
