import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
} from '@material-ui/core';
import { getAllAdherents } from '../../api/apiBack';
import { setAdherents } from '../../store/adherent/actionCreator';
import CollecteSelectFilter from './CollecteSelectFilter';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    width: '400',
  },
}));

/**
 *
 * @param {*} param0
 */
const FormSelectAdherent = ({ setAdherentId }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const adherents = useSelector((state) => state.adherent.adherents);
  const typologyId = useSelector((state) => state.adherent.typology);
  const distinctZipCodeId = useSelector(
    (state) => state.adherent.distinctZipCode
  );

  // generic onChange for input text
  const handleChange = (e) => {
    setAdherentId(e.target.value);
  };

  const adherentsPromise = (result) => {
    dispatch(setAdherents(result.data));
  };

  const failPromise = () => {};

  useEffect(() => {
    const promiseAdherents = getAllAdherents();
    promiseAdherents.then(adherentsPromise, failPromise);
  }, []);

  return (
    <>
      <Grid container>
        <Grid item sm={12} lg={6}>
          <CollecteSelectFilter />
        </Grid>
        <Grid item sm={12} lg={6}>
          <FormControl className={classes.root}>
            <InputLabel htmlFor="statusId">Selectionner un adhérent</InputLabel>
            <Select
              variant="filled"
              name="adherent"
              onChange={handleChange}
              style={{ width: '400px' }}
            >
              {adherents
                .filter((elmt) => elmt.statusId !== 1 && elmt.statusId !== 2)
                .filter(
                  (elmt) => elmt.typologyId === typologyId || typologyId === 0
                )
                .filter(
                  (elmt) =>
                    elmt.zipCodeId === distinctZipCodeId ||
                    distinctZipCodeId === 0
                )
                .map((s) => (
                  <option value={s.id}>{s.sign}</option>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

FormSelectAdherent.propTypes = {
  setAdherentId: PropTypes.number.isRequired,
};

export default FormSelectAdherent;
