import { useEffect, useState } from 'react';
import { getWeightByCriterias } from '../../api/apiBack';
import BarGraphicComponent from './BarGraphicComponent';

const token = localStorage.getItem('TOKEN');

export default function BarGraphic() {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (token) {
      getWeightByCriterias('yearMonth').then((results) => {
        return setDatas(results.data);
      });
    }
  }, [token]);

  return <BarGraphicComponent datas={datas} />;
}
