import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getAllAdmin } from '../../../api/apiBack';
import GenericTableHead from '../../GenericTable/GenericTableHead';
import CreateAdminCard from './CreateAdminCard';

export default function AdminTab() {
  const columns = ['PRÉNOM', 'NOM', 'EMAIL', 'TÉLÉPHONE'];
  const [admins, setAdmins] = useState([]);
  const [isDisplay, setIsDiplay] = useState(false);

  useEffect(() => {
    getAllAdmin().then((result) => setAdmins(result.data));
  }, []);

  const handleAdd = (elmt) => {
    setAdmins(admins.concat(elmt));
    setIsDiplay(false);
  };

  function handleClick() {
    setIsDiplay(true);
  }

  return (
    <>
      <Table>
        <GenericTableHead headArray={columns} />
        <TableBody>
          {admins.map((admin) => (
            <TableRow hover key={admin.email}>
              <TableCell>{admin.firstname}</TableCell>
              <TableCell>{admin.lastname}</TableCell>
              <TableCell>{admin.email}</TableCell>
              <TableCell>{admin.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        variant="contained"
        style={{ marginTop: '20px' }}
        onClick={handleClick}
      >
        Créer un nouvelle Administrateur
      </Button>
      {isDisplay ? <CreateAdminCard handleAdd={handleAdd} /> : <div />}
    </>
  );
}
