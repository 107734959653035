import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  // FormControl,
  // InputLabel,
  // makeStyles,
  // Select,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { postHarvestPoint } from '../../api/apiBack';

const HarvestPointRowCreate = ({ harvestSiteId, addRow }) => {
  // const classes = useStyles();
  const [harvestPoint, setHarvestPoint] = useState({
    geolocLat: 0,
    geolocLong: 0,
    harvestSiteId,
    collectionSiteStateId: 0,
  });
  // const css = useSelector(
  //   (state) => state.collectionSiteState.collectionSiteStates
  // );

  // affect fields values to harvestSiteState
  const handleField = (e) => {
    setHarvestPoint((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const postSuccess = (results) => {
    addRow(results.data);
  };
  const failPromise = () => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const postRequestHarvestPoint = postHarvestPoint(harvestPoint);
    postRequestHarvestPoint.then(postSuccess, failPromise);
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          variant="filled"
          type="text"
          label="Latitude"
          value={harvestPoint.geolocLat}
          id="geolocLat"
          name="geolocLat"
          onChange={handleField}
        />
      </TableCell>
      <TableCell>
        {' '}
        <TextField
          variant="filled"
          type="text"
          label="Longitude"
          value={harvestPoint.geolocLong}
          id="geolocLong"
          name="geolocLong"
          onChange={handleField}
        />
      </TableCell>
      <TableCell>
        {/* select article
        <FormControl className={classes.root}>
          <InputLabel htmlFor="zipCodeId">City</InputLabel>
          <Select
            name="zipCodeId"
            value={harvestPoint ? harvestPoint.zipCodeId : citySelectValue}
            onChange={handleField}
          >
            {css.map((s) => (
              <option key={s.id} value={s.id}>
                {s.label}
              </option>
            ))} 
          </Select>
        </FormControl> */}
      </TableCell>
      <TableCell>
        <Button variant="contained" onClick={handleSubmit}>
          Ajouter
        </Button>
      </TableCell>
    </TableRow>
  );
};

HarvestPointRowCreate.propTypes = {
  harvestSiteId: PropTypes.number.isRequired,
  addRow: PropTypes.func.isRequired,
};
export default HarvestPointRowCreate;
